import React from 'react'

const containerStyle = {
    width: '90vw',
    maxWidth: 1410,
    minWidth: 325,
    marginInline: 'auto',
}

const Container = ({ children, style }) => {
    return (
        <div style={{
            ...containerStyle,
            ...style
        }}>{children}</div>
    )
}
export default Container;
