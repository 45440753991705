import { useMediaQuery } from "@mui/material";
import React from "react";
import { Row } from "react-bootstrap";
import styles from "./CertificateComponent.module.css";

export default function CertificateComponent({ item }) {
   const [active, setActive] = React.useState(false);
   const matches = useMediaQuery("(max-width:600px)");

   const [hovered, setHovered] = React.useState(false);

   return (
      <Row
         className={styles.card}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
         style={{
            display: matches ? "flex" : "auto",
            justifyContent: matches ? "center" : "auto",
            margin:0
         }}
         onClick={() => setActive(!active)}>
         <div className={`${styles.details} ${matches && "d-none"}`}>
            <h1
               style={{
                  color: "#29588C",
                  fontSize: 24,
                  display: hovered ? "block" : "none",
               }}>
               {item.name}
            </h1>
            <p
               style={{
                  color: "#29588C",
                  fontSize: 14,
                  width: 700,
                  display: hovered ? "block" : "none",
               }} dangerouslySetInnerHTML={{
                  __html: item.description
                }}>
            </p>
         </div>
         <img alt="certification" src={item.icon} width="155" />
         {matches && active && (
            <div>
               <h1
                  style={{
                     color: "#29588C",
                     fontSize: 24,
                  }}>
                  {item.name}
               </h1>
               <p
                  style={{
                     color: "#29588C",
                     fontSize: 14,
                  }}>
                  {item.description}
               </p>
            </div>
         )}
      </Row>
   );
}
