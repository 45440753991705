import React from "react";
import Logo from "../../assets/images/logo_blue.svg";
import {
   AiFillFacebook,
   AiFillLinkedin,
   AiFillInstagram,
} from "react-icons/ai";
import styles from "./footer.module.css";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import { Services } from "../../apis/Services/Services";
import handleErrors from "../../hooks/handleErrors";

const Footer = () => {
   const location = useLocation();
   const history = useHistory();
   const matches = useMediaQuery("(max-width:600px)");
   const [social_links, setSocialLinks] = React.useState({});

   React.useEffect(() => {
      getSocialData();
   }, []);
   const getSocialData = () => {
      Services.getSocialLinks("")
         .then((res) => {
            setSocialLinks(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };
   return (
      <footer
         style={{
            position: location.pathname === "/" ? "absolute" : "unset",
            bottom: 0,
            width: "100%",
         }}>
         <Row
            className="justify-content-between"
            style={{
               margin: matches && 0,
            }}>
            <Col lg={11} className={styles["right-side"]}>
               <div className="container-fluid">
                  <Row className="align-items-center p-2 text-center">
                     <Col lg={5}>
                        <Row className="align-items-center">
                           <Col lg={6}>
                              <img
                                 src={Logo}
                                 alt="Detac logo"
                                 onClick={() => history.push("/")}
                              />
                           </Col>
                           {!matches && (
                              <Col lg={6}>
                                 <div
                                    className={styles["link"]}
                                    style={{
                                       textAlign: "end",
                                    }}
                                    onClick={() => history.push("/contact-us")}>
                                    CONTACT US
                                 </div>
                              </Col>
                           )}
                        </Row>
                     </Col>
                     {!matches && (
                        <Col lg={2} className="text-lg-end">
                           <a
                              href={social_links.facebook_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillFacebook
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                           <a
                              href={social_links.linkedin_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillLinkedin
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                           <a
                              href={social_links.instagram_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillInstagram
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                        </Col>
                     )}
                     <Col lg={5} className="col-md-5">
                        <Row className="row justify-content-md-end">
                           {matches ? (
                              <>
                                 <Col lg={12} sm={12} className="text-left p-0">
                                    <p
                                       className={styles["link"]}
                                       style={{
                                          textAlign: "left",
                                       }}
                                       onClick={() =>
                                          history.push("/contact-us")
                                       }>
                                       CONTACT US
                                    </p>
                                 </Col>
                                 <Col
                                    lg={12}
                                    sm={12}
                                    className="text-left p-0"
                                    onClick={() =>
                                       history.push("/terms-conditions")
                                    }>
                                    <p
                                       className={styles["link"]}
                                       style={{
                                          textAlign: "left",
                                       }}>
                                       TERMS & CONDITIONS
                                    </p>
                                 </Col>
                                 <Col
                                    lg={12}
                                    sm={12}
                                    className="text-left p-0"
                                    onClick={() =>
                                       history.push("/privacy-policy")
                                    }>
                                    <p
                                       className={styles["link"]}
                                       style={{
                                          textAlign: "left",
                                       }}>
                                       PRIVACY POLICY
                                    </p>
                                 </Col>
                              </>
                           ) : (
                              <>
                                 <p
                                    className={styles["link"]}
                                    onClick={() =>
                                       history.push("/terms-conditions")
                                    }>
                                    TERMS & CONDITIONS
                                 </p>
                                 <p
                                    className={styles["link"]}
                                    onClick={() =>
                                       history.push("/privacy-policy")
                                    }>
                                    PRIVACY POLICY
                                 </p>
                              </>
                           )}
                        </Row>
                     </Col>
                     {matches && (
                        <Col lg={2} className="text-lg-end my-2">
                           <a
                              href={social_links.facebook_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillFacebook
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                           <a
                              href={social_links.linkedin_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillLinkedin
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                           <a
                              href={social_links.instagram_link}
                              target="_blank"
                              rel="noopener noreferrer">
                              <AiFillInstagram
                                 style={{
                                    fontSize: 25,
                                    color: "#29588c",
                                 }}
                              />
                           </a>
                        </Col>
                     )}
                  </Row>
               </div>
            </Col>
            <Col lg={1} className={`col-md-1 p-0 ${styles["left-side"]}`}></Col>
         </Row>

         <div className="container-fluid">
            <Row className="justify-content-center p-2">
               <p
                  style={{
                     color: location.pathname === "/" ? "white" : "#29588c",
                  }}>
                  All rights reserved to DETAC 2021
               </p>
            </Row>
         </div>
      </footer>
   );
};
export default Footer;
