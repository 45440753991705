import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { MenuItem, useMediaQuery } from "@mui/material";
import { Container, Nav, Navbar } from "react-bootstrap";
import PopupState, { bindHover } from "material-ui-popup-state";
import LogoBlue from "../../assets/images/logo_blue.svg";
import styles from "./Navbar.module.css";
import { Services } from "../../apis/Services/Services";

const LinkNav = ({ text, pathname, open }) => {
   const matches = useMediaQuery("(max-width:600px)");
   return (
   <Nav.Link
      className={styles["nav-text"]}
      style={{
         color: !matches || open ? (pathname!=='/'?"#326295":"white") : "#326295",
      }}>
      {text}
   </Nav.Link>
   )
   };

export default function Navbars() {
   const location = useLocation();
   const history = useHistory();
   const matches = useMediaQuery("(max-width:600px)");

   const [expanded, setExpanded] = React.useState(false);
   const [cateogries, setCateogries] = React.useState([]);

   useEffect(() => {
      getCategories();
   }, []);

   const getCategories = () => {
      Services.getProjectsCateogries()
         .then((res) => {
            setCateogries(res);
         })
         .catch((err) => console.log(err));
   };

   return (
      <Navbar
         collapseOnSelect
         expanded={expanded}
         expand="lg"
         className={styles.transparent}
         style={{
            position: location.pathname === "/" ? !matches ? "absolute" : "unset" : "unset",
            backgroundColor:
               location.pathname === "/" ? matches ? "#e6e7e8": "transparent" : "white",
            boxShadow: location.pathname !== "/" ? "0 0 0 black" : "none",
            zIndex: 2,
         }}>
         <Container className={"align-items-lg-start"}>
            <Navbar.Brand onClick={() => history.push("/")}>
               <img
                  src={LogoBlue}
                  width="160"
                  className="d-inline-block align-center pointer"
                  style={{
                     cursor: "pointer"
                  }}
                  alt="Detac logo"
               />
            </Navbar.Brand>
            <Navbar.Toggle
               aria-controls="responsive-navbar-nav"
               onClick={() => setExpanded(!expanded)}
            />
            <Navbar.Collapse
               id="responsive-navbar-nav"
               style={{
                  backgroundColor:
                     location.pathname === "/" && matches
                        ? "transparent"
                        : matches && "white",
               }}>
               <Nav className="me-auto"></Nav>
               <Nav>
                  <div
                     onClick={() => {
                        history.push("/about-us");
                        setExpanded(false);
                     }}>
                     <LinkNav text="ABOUT US" pathname={location.pathname} />
                  </div>
                  <div
                     onClick={() => {
                        history.push("/our-services");
                        setExpanded(false);
                     }}>
                     <LinkNav
                        text="OUR SERVICES"
                        pathname={location.pathname}
                     />
                  </div>
                  {matches ? (
                     <div
                        onClick={() => {
                           history.push("/our-projects")
                           setExpanded(false);
                        }}>
                        <LinkNav
                           text="OUR PROJECTS"
                           pathname={location.pathname}
                        />
                     </div>
                  ) : (
                     <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                           <React.Fragment>
                              <div
                                 id="demo-popup-menu"
                                 className={
                                    popupState.isOpen
                                       ? styles["nav-dropdown"]
                                       : "position-relative"
                                 } {...bindHover(popupState)}>
                                 <div onClick={() =>
                                    history.push("/our-projects")
                                 }>
                                    <LinkNav
                                       text="OUR PROJECTS"
                                       open={popupState.isOpen}
                                       pathname={location.pathname}
                                    />
                                 </div>
                                 <div
                                    className="MuiPaper-root MuiPopover-paper"
                                    style={{
                                       position: "absolute",
                                       display: popupState.isOpen
                                          ? "block"
                                          : "none",
                                    }}>
                                    {cateogries.map((item) => (
                                       <MenuItem
                                          key={item.id}
                                          onClick={() =>
                                             history.push("/our-projects", {
                                                category: item.id,
                                             })
                                          }>
                                          {item.name}
                                       </MenuItem>
                                    ))}
                                 </div>
                              </div>
                           </React.Fragment>
                        )}
                     </PopupState>
                  )}
                  <div
                     onClick={() => {
                        history.push("/sustainablity");
                        setExpanded(false);
                     }}>
                     <LinkNav
                        text="SUSTAINABILITY"
                        pathname={location.pathname}
                     />
                  </div>
                  <div
                     onClick={() => {
                        history.push("/news-room");
                        setExpanded(false);
                     }}>
                     <LinkNav text="NEWSROOM" pathname={location.pathname} />
                  </div>
               </Nav>
            </Navbar.Collapse>
         </Container>
      </Navbar>
   );
}
