import { toast } from "react-toastify";
toast.configure();
export default function handleErrors(props) {
   const { err } = props;
   let error;
   console.log("'err'", err);
   if (Object.keys(err)[0] !== "line") {
      if (err.detail) {
         toast.error(err.detail);
      } else if (err.detials) {
         toast.error(err?.detials);
      } else if (err && Object.keys(err)) {
         toast.error(err[Object.keys(err)[0]][0]);
      } else if (Object.keys !== 0) {
         toast.error(Object.values(err)[0]);
      } else if (err.message) {
         toast.error(err.message);
      } else {
         toast.error("Something went wrong.");
      }
   }
   return error;
}
