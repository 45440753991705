import React, { useEffect } from "react";
import {
   Switch,
   Route,
   BrowserRouter,
   useLocation,
} from "react-router-dom";
import Footer from "./components/footer/footer";
//components
import Navbars from "./components/navbar/Navbar";
import Home from "./pages";
//pages
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";
import NewsRoom from "./pages/news-room";
import NewsPage from "./pages/news-room/news-page";
import OurProjects from "./pages/our-projects";
import ProjectPage from "./pages/our-projects/project-page";
import OurServices from "./pages/our-services";
import PrivacyPolicy from "./pages/privacy";
import Sustainablity from "./pages/sustainablity";
import TermsConditions from "./pages/terms";

const ScrollToTop = () => {
   const location = useLocation();

   useEffect(() => {
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth",
      });
   }, [location]);

   return null;
};

const RouterLinks = () => {
   return (
      <BrowserRouter basename="/">
         <ScrollToTop />
         <Navbars />
         <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/our-services" component={OurServices} />
            <Route exact path="/news-room" component={NewsRoom} />
            <Route exact path="/news-room/:id" component={NewsPage} />

            <Route exact path="/sustainablity" component={Sustainablity} />
            <Route exact path="/terms-conditions" component={TermsConditions} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />

            <Route exact path="/our-projects" component={OurProjects} />
            <Route exact path="/our-projects/:id" component={ProjectPage} />
         </Switch>
         <Footer />
      </BrowserRouter>
   );
};

export default RouterLinks;
