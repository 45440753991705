import React, { useEffect, useState } from "react";

import { Row } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { Services } from "../../apis/Services/Services";
import handleErrors from "../../hooks/handleErrors";
import { Button } from "@mui/material";

function LoadMore(props) {
   const [isUrl, setIsUrl] = useState(props.next);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      setIsUrl(props.next);
   }, [props.next]);

   const loadMoreData = () => {
      if (isUrl) {
         setIsLoading(true);
         Services.get_next_page(isUrl)
            .then((res) => {
               props.newData(res);
               setIsUrl(res.next);
               setIsLoading(false);
            })
            .catch((err) => handleErrors({ err: err }));
      }
   };

   return typeof isUrl === "string" ? (
      <div>
         {isLoading ? (
            <Box sx={{ display: "flex" }}>
               <CircularProgress />
            </Box>
         ) : (
               <Button sx={{
                  mt:3
               }} onClick={() => loadMoreData()}>Load more</Button>
         )}
      </div>
   ) : (
      <div />
   );
}

export default LoadMore;
