import React from "react";
import { Col, Row } from "react-bootstrap";
import { Button, FormHelperText, TextField } from "@mui/material";
import Header from "../../components/header/Header";
import styles from "./contact-us.module.css";
import { Services } from "../../apis/Services/Services";
import handleErrors from "../../hooks/handleErrors";
import Container from "../../components/Container";

export default function ContactUs() {
   const [data, setData] = React.useState({});

   const [values, setValues] = React.useState({
      message: "",
      email: "",
   });

   const [errors, setErrors] = React.useState({
      message: null,
      email: null,
   });

   React.useEffect(() => {
      getSiteConfig();
   }, []);

   const getSiteConfig = () => {
      Services.getSiteConfigration()
         .then((res) => {
            setData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };
   const sendData = () => {
      if (
         values.email &&
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
         setErrors({ ...errors, email: "Invalid email address" });
      } else if (values.email === "" || values.message === "") {
         values.email === "" &&
            setErrors({ ...errors, email: "Email address is required" });
         values.message === "" &&
            setErrors({ ...errors, message: "Message is required" });
      } else {
         Services.sendContactUs(values)
            .then((res) => {
               setValues({
                  message: "",
                  email: "",
               });
            })
            .catch((err) => handleErrors({ err: err }));
      }
   };
   return (
      <div id={styles["contact-us"]}>
         <Header title="CONTACT US" />
         <Container>
            <Row>
               <Col lg={6}>
                  <div className={styles["info"]}>
                     <span>Telephone : </span>
                     {data.telephone}
                  </div>
                  <div className={styles["info"]}>
                     <span>Fax : </span>
                     {data.fax}
                  </div>
                  <div className={styles["info"]}>
                     <span>Email : </span>
                     {data.email}
                  </div>
                  <div className={styles["info"]}>
                     <span>Location : </span>
                     {data.location}
                  </div>
               </Col>
               <Col lg={6}>
                  <iframe
                     src={data.map_url}
                     width="100%"
                     height="350"
                     title="map location"
                     loading="lazy"></iframe>
               </Col>
            </Row>
            <Row className="mt-5">
               <Col lg={12}>
                  <TextField
                     className={styles["outlined-textarea"]}
                     placeholder="Write your message here"
                     error={errors.message}
                     onChange={(e) => {
                        setValues({ ...values, message: e.target.value });
                        setErrors({ ...errors, message: null });
                     }}
                     rows={8}
                     multiline
                     label=""
                  />
                  {errors.message && (
                     <FormHelperText
                        style={{
                           color: "red",
                        }}>
                        {errors.message}
                     </FormHelperText>
                  )}
               </Col>
               <Col lg={6}>
                  <TextField
                     className={styles["outlined-textarea"]}
                     error={errors.email}
                     onChange={(e) => {
                        setValues({ ...values, email: e.target.value });
                        setErrors({ ...errors, email: null });
                     }}
                     placeholder="Email"
                     label=""
                  />
                  {errors.email && (
                     <FormHelperText
                        style={{
                           color: "red",
                        }}>
                        {errors.email}
                     </FormHelperText>
                  )}
               </Col>
               <Col lg={6}>
                  <Button
                     variant="contained"
                     className={styles["outlined-btn"]}
                     onClick={() => sendData()}>
                     Send
                  </Button>
               </Col>
            </Row>
         </Container>
      </div>
   );
}
