import * as React from "react";
import {
   Accordion,
   AccordionSummary,
   AccordionDetails,
   useMediaQuery,
   Typography
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import styles from "./service.module.css";
import iconI from "../../assets/images/Vector.svg";

const Icon = () => (
   <div className={styles.icon}>
      <img
         src={iconI}
         alt="arrow-icon"
         className="p-2"
         width={35}
         height={35}
      />
   </div>
);

export default function ServiceComponent({
   item,
   index,
   isExpanded,
   setExpand,
}) {
   const [hovered, setHovered] = React.useState(false);
   const matches = useMediaQuery("(max-width:600px)");

   React.useEffect(() => {
      setHovered(false);
   }, [isExpanded]);

   return (
      <Row>
         <Col lg={7}>
            <Accordion
               square
               className={styles.accordion}
               expanded={isExpanded === index}
               onChange={setExpand(index)}
               classes={styles.AccordionActive}
               onMouseEnter={() => isExpanded === false && setHovered(true)}
               onMouseLeave={() => isExpanded === false && setHovered(false)}>
               <AccordionSummary
                  expandIcon={<Icon />}
                  aria-controls="panel1a-content"
                  className={`${
                     isExpanded !== index && isExpanded === false && hovered
                        ? styles["panel1a-hovered"]
                        : ""
                  }`}
                  id={styles["panel1a-header"]}>
                  <Typography
                     className={`${styles.label} ${
                        isExpanded === index ? styles["active"] : ""
                     }`}>
                     {item.title}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  {matches && (
                     <img
                        src={item.image}
                        alt={item.title}
                        width="100%"
                        className="mb-2"
                     />
                  )}
                  <Typography
                     className={`${styles.label} ${styles["active"]}`}
                     style={{
                        fontSize: 16,
                     }}>
                     {item.description}
                  </Typography>
               </AccordionDetails>
            </Accordion>
         </Col>
         <Col lg={5} className="position-relative">
            <img
               src={item.image}
               alt={item.title}
               width="100%"
               className={`${styles["image"]} ${
                  hovered || isExpanded === index ? styles["show"] : ""
               }`}
            />
         </Col>
      </Row>
   );
}
