import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Container from "../components/Container";
import { BsFillPlusSquareFill } from "react-icons/bs";
import Carousels from "react-bootstrap/Carousel";
import Carousel from "react-elastic-carousel";
import styled from "styled-components";
import styles from "./home.module.css";

import PersonCard from "../components/cards/person-card/PersonCard";
import SliderImage from "../components/slider-image/SliderImage";
import SisterCard from "../components/sister-card/SisterCard";
import NewsCard from "../components/news-card/NewsCard";
import Progress from "../components/progress/progress";

import ButtonComponent from "../components/buttonComponent/ButtonComponent";
import CertificateComponent from "../components/CertificateComponent/CertificateComponent";
import Logo from "../assets/images/logo_blue.svg";
import { Services } from "../apis/Services/Services";
import handleErrors from "../hooks/handleErrors";
import gsap from "gsap/dist/gsap.js";
import { useCountUp } from "react-countup";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const Count = ({ num }) => {
   const countUpRef = React.useRef(null);
   const { pauseResume, reset } = useCountUp({
      ref: countUpRef,
      start: 0,
      end: num,
      delay: 2,
      duration: 5,
   });
   useEffect(() => {
      ScrollTrigger.create({
         trigger: countUpRef.current,
         onEnter: (e) => {
            reset(e);
            pauseResume(e);
         },
      });
   }, []);
   return <div ref={countUpRef} className={`${styles["title"]} px-0`} style={{ fontSize: "6.250vw", lineHeight: 1 }} />;
};

const breakPoints = [
   { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false },
   { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: true },
];

const bannerBreakPoints = [
   { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: true },
   {
      width: 550,
      itemsToShow: 4,
      itemsToScroll: 1,
      pagination: true,
   },
];

const Text = styled.div`
   color: #e6e7e8;
   font-family: "MyriadPro bold";
   font-size: 5.5vw;
   text-shadow: 0 0 2.5px #001c31;
   text-align: end;
   padding: 0;
   position: relative;
   right: 0;
   padding-left: 5px;
   padding-right: ${(props) => (props.afterText === "TIME" ? 5 : 0)}px;
   &::after {
      content: "${(props) => props.afterText || ""}";
      color: rgb(255, 172, 42);
      font-family: "MyriadPro bold";
      text-shadow: rgb(255 172 42) 0px 0px 1.5px;
      position: absolute;
      padding-left: ${(props) => (props.afterText === "TIME" ? 10 : 0)}px;
   }
`;

const Home = (props) => {
   const [activeStep, setActiveStep] = React.useState(0);
   const [moreDetails, setOpened] = React.useState(false);
   const matches = useMediaQuery("(max-width:600px)");

   const [sliders, setSliders] = React.useState([]);
   const [news, setNews] = React.useState([]);
   const [behind_success, setBehindSuccess] = React.useState([]);

   const [data, setData] = React.useState({});

   const [counts, setCounts] = React.useState({});

   const myRef = useRef(null);

   useEffect(() => {
      getSliders();
      getNews();
      getData();
      getBehindSuccess();
      getCounter()
   }, []);

   useEffect(() => {
      if (props.location.hash.includes("#news")) {
         myRef.current.scrollIntoView();
      }
   }, [props]);

   const getSliders = () => {
      Services.getSlider()
         .then((res) => {
            setSliders(res.results);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getNews = () => {
      Services.getNews("")
         .then((res) => {
            setNews(res.results);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getCounter = () => {
      Services.getCounts("")
         .then((res) => {
            let data = res.data.slice(0, 4)
            data = data.map(item => {
               let arr = item.count
               let number = arr.split(" ")[0]
               let text = arr.split(" ").slice(1).join(" ")
               return {
                  number: Number(number),
                  text: text,
                  ...item
               }
            })
            setCounts({ ...res, data })
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getBehindSuccess = () => {
      Services.getBehindSuccess("")
         .then((res) => {
            setBehindSuccess(res.results);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getData = () => {
      Services.getHomeData()
         .then((res) => {
            setData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const handleStepChange = (step) => {
      setActiveStep(step);
      setOpened(false);
   };

   return (
      <div className="position-relative">
         <div
            className="position-relative"
            style={{
               height: "100vh",
            }}>
            <Carousels
               activeIndex={activeStep}
               indicators={false}
               controls={false}
               onSelect={handleStepChange}>
               {React.Children.toArray(
                  sliders.map((item) => (
                     <Carousels.Item>
                        <SliderImage
                           item={item}
                           active={{ item: sliders[activeStep] }}
                        />
                     </Carousels.Item>
                  ))
               )}
            </Carousels>

            <div
               style={{
                  background: "#E6E7E8",
                  position: "absolute",
                  width: "30vw",
                  height: "100%",
                  top: 0,
                  zIndex: 12,
                  display: matches && "none",
               }}>
               <div
                  style={{
                     background: "#E6E7E8",
                     height: "100%",
                  }}>
                  <Row className="justify-content-center mx-0 mb-5">
                     <img
                        src={Logo}
                        width="160px"
                        style={{
                           width: 160,
                           padding: ".5rem",
                        }}
                        className="d-inline-block align-center pointer"
                        alt="Detac logo"
                     />
                  </Row>
                  {!moreDetails ? (
                     <div
                        className="d-flex flex-column"
                        style={{
                           height: "80%",
                           justifyContent: "space-between",
                        }}>
                        <p
                           className="text-end"
                           style={{
                              color: "#29588c",
                              fontFamily: "MyriadPro bold",
                              fontSize: 25,
                              padding: ".5rem",
                           }}>
                           {sliders[activeStep]?.short_description}
                        </p>

                        <Row className="justify-content-center mx-0 my-5 position-relative">
                           <Text
                              afterText={
                                 sliders[activeStep]?.colored_title_section
                              }>
                              {sliders[activeStep]?.transparent_title_section}
                           </Text>
                        </Row>

                        <div>
                           <Row className="justify-content-center mx-0 mt-5">
                              <p
                                 className="text-end"
                                 style={{
                                    color: "#29588c",
                                    fontFamily: "MyriadPro",
                                 }}>
                                 {sliders[activeStep]?.bottomText}
                              </p>
                           </Row>

                           <Row className="justify-content-center mx-0 mb-5">
                              {sliders[activeStep]?.description && (
                                 <p
                                    onClick={() =>
                                       sliders[activeStep]?.description &&
                                       setOpened(true)
                                    }
                                    className="text-center"
                                    style={{
                                       color: "#29588c",
                                       fontFamily: "MyriadPro",
                                       fontSize: 14,
                                       cursor: "pointer",
                                    }}>
                                    Find Out More
                                    <BsFillPlusSquareFill
                                       style={{ paddingLeft: 5, fontSize: 25 }}
                                    />
                                 </p>
                              )}
                           </Row>
                        </div>
                     </div>
                  ) : (
                     <Row
                        className={`${moreDetails ? styles["more-details"] : ""
                           } hide p-2 mx-0`}>
                        <p
                           style={{
                              color: "#29588c",
                              fontFamily: "MyriadPro bold",
                              marginBottom: 15,
                           }}>
                           {sliders[activeStep]?.bottomText}
                        </p>
                        <p
                           className="text-start"
                           style={{
                              color: "#29588c",
                              fontFamily: "MyriadPro",
                           }}>
                           {sliders[activeStep]?.description}
                        </p>
                     </Row>
                  )}
               </div>
            </div>
            <Progress
               pages={sliders}
               activePage={activeStep}
               onClick={(i) => {
                  setActiveStep(i);
                  setOpened(false);
               }}
               btnStyle={{
                  color: matches ? "#29588c" : "white",
               }}
               style={{
                  position: !matches ? "absolute" : "unset",
                  bottom: 50,
                  right: 50,
                  color: "white",
                  marginLeft: "auto",
               }}
            />
         </div>

         <Container>
            <section
               className="position-relative py-5"
               id="news"
               ref={myRef}
               style={{
                  height: 600,
               }}>
               <div className={`${styles["title"]}`}>NEWSROOM</div>

               <div className="py-4">
                  <Carousel
                     showArrows={false}
                     breakPoints={breakPoints}
                     renderPagination={({ pages, activePage, onClick }) => (
                        <Progress
                           pages={pages}
                           activePage={activePage}
                           onClick={onClick}
                           style={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                           }}
                        />
                     )}>
                     {React.Children.toArray(
                        news.map((item) => <NewsCard item={item} />)
                     )}
                  </Carousel>

                  <Row className="justify-content-center">
                     <Col lg={3}>
                        <ButtonComponent
                           text="Explore More"
                           handleClick={() => props.history.push("/news-room")}
                        />
                     </Col>
                  </Row>
               </div>
            </section>

            <section
            className="py-5"
               style={{
                  minHeight: 120,
               }}>
               <div className={`${styles["title"]} pb-5`}>+{counts?.total_projects_count} PROJECTS</div>

               <Row className="pt-4">
                  {counts?.data?.slice(0, 4).map((item, index) => (
                     <Col
                        lg={3}
                        className="row mx-0 align-items-center px-lg-0"
                        key={`analytics_${index}`}>
                        <Count num={item.number} />
                        <div
                           className={`${styles["title"]} pe-0`}
                           style={{ fontSize: "clamp(16px,0.838vw,40px)" }}>
                           {item.text}
                        </div>
                     </Col>
                  ))}
               </Row>
            </section>

            <section className="py-5" style={{
               minHeight: 240,
            }}>
               <div className={`${styles["title"]} pb-5`}>OUR CERTIFICATES</div>

               <Row className="justify-content-between position-relative py-4">
                  {data?.certificates?.map((item, index) => (
                     <Col
                        lg={3}
                        className="text-center"
                        key={`certificate_${index}`}>
                        <CertificateComponent item={item} />
                     </Col>
                  ))}
               </Row>
            </section>

            <section
            className="py-5"
               style={{
                  minHeight: 400,
               }}>
               <div className={`${styles["title"]} pb-5`}>SISTER COMPANIES</div>

               <p
                  className={styles["title"]}
                  style={{
                     fontSize: 16,
                  }}>
                  {data?.sister_companies?.description}
               </p>

               <Row className="justify-content-start py-4">
                  {data.sister_companies?.sister_company_set.map((item) => (
                     <Col
                        lg={4}
                        className="text-start"
                        key={`sister_${item.id}`}>
                        <SisterCard item={item} />
                     </Col>
                  ))}
               </Row>
            </section>
         </Container>

         <section
            className={`py-5 ${styles["brands"]}`}
            style={{
               minHeight: 600,
            }}>
            <Container>
               <div
                  className={`${styles["title"]}`}
                  style={{ color: "white" }}>
                  OUR PARTNERS
               </div>

               <Row className="justify-content-start">
                  {data?.partners?.map((item, index) => (
                     <Col
                        lg={3}
                        xs={6}
                        className="text-center py-4"
                        key={`partner_${index}`}>
                        <img
                           alt={item.name}
                           src={item.icon}
                           width="60%"
                           style={{
                              objectFit: "contain",
                              height: 80,
                           }}
                        />
                     </Col>
                  ))}
               </Row>
               <Row className="justify-content-center">
                  <Col lg={3}>
                     <div
                        className={styles.moreProject}
                        onClick={() =>
                           props.history.push("/our-projects")
                        }>
                        Explore Projects
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>

         <section
            className={`mt-4`}
            style={{
               // marginBottom: !matches ? "-20%" : "-50%",
               minHeight: 600
            }}>
            <Container className="position-relative" style={{
               marginBottom: !matches ? "-20%" : "-50%",
            }}>
               <div className={`${styles["title"]} mb-4`}>BEHIND SUCCESS</div>

               <Row className="justify-content-start pb-5">
                  <Carousel
                     showArrows={false}
                     breakPoints={bannerBreakPoints}
                     renderPagination={({ pages, activePage, onClick }) => pages.length > 1 ? (
                        <Progress
                           pages={pages}
                           activePage={activePage}
                           onClick={onClick}
                           btnStyle={{
                              color: "#ffac2a",
                           }}
                           style={{
                              position: "absolute",
                              bottom: -10,
                              right: 0,
                           }}
                        />
                     ) : <div />}>
                     {behind_success.map((item, index) => (
                        <Col
                           lg={4}
                           sx={12}
                           className="text-start"
                           key={`ceo_${index}`}>
                           <PersonCard item={item} />
                        </Col>
                     ))}
                  </Carousel>
               </Row>
            </Container>
            <footer className={styles.footer}></footer>
         </section>
      </div>
   );
};
export default Home;
