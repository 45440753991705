import React, { useEffect } from "react";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";

export default function Progress({ activePage, pages, onClick,style ,btnStyle}) {
   const [activeStep, setActiveStep] = React.useState(0);

   useEffect(() => {
      setActiveStep(activePage);
   }, [activePage]);

   const handleNext = () => {
      onClick(activeStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleBack = () => {
      onClick(activeStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };

   return (
      <MobileStepper
         variant="progress"
         steps={pages?.length}
         position="static"
         activeStep={activeStep}
         sx={{ maxWidth: 400, flexGrow: 1 ,...style}}
         nextButton={
            <Button
               size="small"
               style={{
                  ...btnStyle
               }}
               onClick={handleNext}
               disabled={activeStep === pages?.length - 1}>
               Next
            </Button>
         }
         backButton={
            <Button
               size="small"
               style={{
                  ...btnStyle
               }}
               onClick={handleBack}
               disabled={activeStep === 0}>
               Prev.
            </Button>
         }
      />
   );
}
