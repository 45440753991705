import * as React from "react";
import { CardActionArea, CardMedia, CardContent, Card, useMediaQuery } from "@mui/material";
import styles from "./project-card.module.css";
import ButtonComponent from "../buttonComponent/ButtonComponent";

export default function ProjectCard({ item }) {
   const [hovered, setHovered] = React.useState(false);
   const matches = useMediaQuery("(max-width:600px)");

   return (
      <Card
         onMouseOver={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
         sx={{ maxWidth: 345, boxShadow: "none", background: 'transparent' }}>
         <CardActionArea>
            <CardMedia
               component="img"
               height="300"
               image={item.image}
               alt={item.title}
            />
            {hovered && <div className={styles.label}>{item.title}</div>}
            {matches ? (
               <CardContent className="px-0">
                  <div className={`${styles["outlined-btn"]} ${styles["active"]}`}>
                     <ButtonComponent text="Project Details" />
                  </div>
               </CardContent>
            ) : (
               <CardContent className="px-0">
                  <div className={`${styles["outlined-btn"]} ${hovered ? styles["active"] : ""}`}>
                     <ButtonComponent text="Project Details" />
                  </div>
               </CardContent>
            )}
         </CardActionArea>
      </Card>
   );
}
