import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../../components/header/Header";
import styles from "./our-projects.module.css";
import NewsCard from "../../components/news-card/NewsCard";
import { Services } from "../../apis/Services/Services";
import LoadMore from "../../components/LoadMore/LoadMore";
import handleErrors from "../../hooks/handleErrors";
import Container from "../../components/Container";

export default function NewsRoom() {
   const [data, setData] = useState([]);
   const [otherData, setOtherData] = useState({});

   useEffect(() => {
      getData();
   }, []);

   const getData = () => {
      Services.getNews("")
         .then((res) => {
            setData(res.results);
            setOtherData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getMoreData = (res) => {
      setOtherData(res);
      setData((old) => [...old, ...res.results]);
   };

   return (
      <div id={styles["our-projects"]}>
         <Header title="NEWSROOM" />

         <Container>
            <div
               className="position-relative"
               style={{
                  minHeight: "100vh",
               }}>
               <Row>
                  {React.Children.toArray(
                     data.map((item) => (
                        <Col lg={4}>
                           <div className="pb-3">
                              <NewsCard item={item} />
                           </div>
                        </Col>
                     ))
                  )}
               </Row>
               <LoadMore next={otherData?.next} newData={getMoreData} />
            </div>
         </Container>
      </div>
   );
}
