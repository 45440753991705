import { ENDPOINTS } from "../EndPoints/Endpoints";
import { Network } from "../Network";

export class Services {
   static get_next_page(url) {
      return Network.fetch(
         url,
         {
            method: "GET",
         },
         true
      );
   }

   static getAboutUs() {
      return Network.fetch(
         ENDPOINTS.aboutUs.url,
         {
            method: ENDPOINTS.aboutUs.method,
         },
         true
      );
   }

   static sendContactUs(values) {
      return Network.fetch(
         ENDPOINTS.contactUs.url,
         {
            body: JSON.stringify(values),
            headers: {
               "Content-Type": "application/json",
            },
            method: ENDPOINTS.contactUs.method,
         },
         true
      );
   }

   static getSiteConfigration() {
      return Network.fetch(
         ENDPOINTS.siteConfig.url,
         {
            method: ENDPOINTS.siteConfig.method,
         },
         true
      );
   }

   static getServices() {
      return Network.fetch(
         ENDPOINTS.services.url,
         {
            method: ENDPOINTS.services.method,
         },
         true
      );
   }

   static getSlider() {
      return Network.fetch(
         ENDPOINTS.sliders.url,
         {
            method: ENDPOINTS.sliders.method,
         },
         true
      );
   }

   static getProjectsCateogries() {
      return Network.fetch(
         ENDPOINTS.projectsCateogries.url,
         {
            method: ENDPOINTS.projectsCateogries.method,
         },
         true
      );
   }

   static getProjects(params) {
      return Network.fetch(
         ENDPOINTS.projects.url(params),
         {
            method: ENDPOINTS.projects.method,
         },
         true
      );
   }

   static getHomeData() {
      return Network.fetch(
         ENDPOINTS.home.url,
         {
            method: ENDPOINTS.home.method,
         },
         true
      );
   }

   static getNews(params) {
      return Network.fetch(
         ENDPOINTS.news.url(params),
         {
            method: ENDPOINTS.news.method,
         },
         true
      );
   }

   static getCounts(params) {
      return Network.fetch(
         ENDPOINTS.getCounts.url(params),
         {
            method: ENDPOINTS.getCounts.method,
         },
         true
      );
   }

   static getSus(params) {
      return Network.fetch(
         ENDPOINTS.getSus.url(params),
         {
            method: ENDPOINTS.getSus.method,
         },
         true
      );
   }

   static getBehindSuccess(params) {
      return Network.fetch(
         ENDPOINTS.getBehindSuccess.url(params),
         {
            method: ENDPOINTS.getBehindSuccess.method,
         },
         true
      );
   }

   static getSocialLinks(params) {
      return Network.fetch(
         ENDPOINTS.getSocialLinks.url(params),
         {
            method: ENDPOINTS.getSocialLinks.method,
         },
         true
      );
   }
}
