import React from 'react'
import styles from './ButtonComponent.module.css';

export default function ButtonComponent({ text ,handleClick }) {
    return (
        <div onClick={handleClick} className={`${styles["outlined-btn"]}`}>
            {text}
        </div>
    )
}
