import DomainUrl from "../Domains";

export const ENDPOINTS = {
   aboutUs: {
      url: `${DomainUrl}/about-us/`,
      method: "GET",
   },
   contactUs: {
      url: `${DomainUrl}/contact-us/`,
      method: "POST",
   },
   siteConfig: {
      url: `${DomainUrl}/site-configuration/`,
      method: "GET",
   },
   services: {
      url: `${DomainUrl}/services/`,
      method: "GET",
   },
   sliders: {
      url: `${DomainUrl}/slider/`,
      method: "GET",
   },

   projectsCateogries: {
      url: `${DomainUrl}/project-categories/`,
      method: "GET",
   },
   projects: {
      url: (params) => `${DomainUrl}/projects/${params}`,
      method: "GET",
   },

   home: {
      url: `${DomainUrl}/budget-page/`,
      method: "GET",
   },

   news: {
      url: (params) => `${DomainUrl}/news-rooms/${params}`,
      method: "GET",
   },
   getCounts: {
      url: (params) => `${DomainUrl}/projects-count/${params}`,
      method: "GET",
   },
   getSus: {
      url: (params) => `${DomainUrl}/sustainability`,
      method: "GET",
   },

   getBehindSuccess: {
      url: (params) => `${DomainUrl}/behind-success/${params}`,
      method: "GET",
   },

   getSocialLinks: {
      url: (params) => `${DomainUrl}/social-media/${params}`,
      method: "GET",
   },
};
