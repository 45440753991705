import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import { Box, Tabs, Tab } from "@mui/material";
import Header from "../../components/header/Header";
import Progress from "../../components/progress/progress";
import ProjectCard from "../../components/project-card/projectCard";
import { Services } from "../../apis/Services/Services";
import handleErrors from "../../hooks/handleErrors";
import styles from "./our-projects.module.css";
import Container from "../../components/Container";

const breakPoints = [
   { width: 1, itemsToShow: 1, itemsToScroll: 1, pagination: false },
   { width: 550, itemsToShow: 3, itemsToScroll: 1, pagination: true },
];

export default function OurProjects(props) {
   const [data, setData] = useState([]);
   const [cateogries, setCateogries] = useState([]);

   const [selectedCategory, setSelectedCateogry] = useState({});

   useEffect(() => {
      getCategories();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      selectedCategory?.id && getData(selectedCategory?.id);
      // eslint-disable-next-line
   }, [selectedCategory?.id]);

   useEffect(() => {
      if (props.location.state?.category && cateogries.length !== 0) {
         setSelectedCateogry(
            cateogries.find(
               (item) => item.id === props.location.state?.category
            )
         );
      }
      // eslint-disable-next-line
   }, [props.location.state?.category]);

   const getCategories = () => {
      Services.getProjectsCateogries()
         .then((res) => {
            setCateogries(res);
            if (props.location.state?.category) {
               setSelectedCateogry(
                  res.find((item) => item.id === props.location.state?.category)
               );
            } else {
               setSelectedCateogry(res[0]);
            }
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getData = (id = selectedCategory?.id) => {
      Services.getProjects(`?category=${id}`)
         .then((res) => {
            setData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const handleChange = (event, newValue) => {
      setSelectedCateogry(cateogries[newValue]);
   };

   return (
      <div id={styles["our-projects"]}>
         <Header title="OUR PROJECTS" />

         <Container>
            <Box sx={{ marginBottom: 15 }}>
               <Tabs
                  value={cateogries.findIndex(
                     (item) => item.id === selectedCategory.id
                  )}
                  onChange={handleChange}
                  centered
                  variant="scrollable"
                  aria-label="Our projects tabs"
                  >
                  {cateogries.map((item, index) => (
                     <Tab label={item.name} key={index} />
                  ))}
               </Tabs>
            </Box>

            <div
               className="position-relative"
               style={{
                  minHeight: "100vh",
               }}>
               <Row className="justify-content-between pb-5">
                  <Col lg={3}>
                     <div className={styles["title"]}>
                        {selectedCategory.name}
                     </div>
                  </Col>
               </Row>

               <Carousel
                  showArrows={false}
                  breakPoints={breakPoints}
                  renderPagination={({ pages, activePage, onClick }) => (
                     <Progress
                        pages={pages}
                        activePage={activePage}
                        onClick={onClick}
                        style={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                        }}
                     />
                  )}>
                  {React.Children.toArray(
                     data.map((item) => (
                        <div
                           onClick={() =>
                              props.history.push(`/our-projects/${item.id}`)
                           }>
                           <ProjectCard item={item} />
                        </div>
                     ))
                  )}
               </Carousel>
            </div>
         </Container>
      </div>
   );
}
