import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { Row } from "react-bootstrap";
import { BsFillPlusSquareFill } from "react-icons/bs";
import styled from "styled-components";
import styles from "./slider-image.module.css";

const Text = styled.div`
   color: #fff;
   font-family: "MyriadPro bold";
   font-size: ${(props) => (!props.matches ? "5.5vw" : "10.5vw")};
   text-shadow: 0 0 2.5px #001c31;
   text-align: end;
   padding: 0;
   position: relative;
   right: 0;
   padding-left: 0px;
   &::after {
      margin-left: ${(props) => (props.afterText === "TIME" ? -5 : 0)}px;
      content: "${(props) => props.afterText || ""}";
      color: rgb(255, 172, 42);
      font-family: "MyriadPro bold";
      text-shadow: rgb(255 172 42) 0px 0px 1.5px;
      position: absolute;
   }
`;

export default function SliderImage({ item, active }) {
   const [moreDetails, setOpened] = React.useState(false);
   const matches = useMediaQuery("(max-width:600px)");

   const [activeSlider, setActiveSlider] = React.useState({});

   useEffect(() => {
      setActiveSlider(active);
      setOpened(false)
   }, [active]);

   return (
      <div className="w-100">
         {!matches&&(<div style={{
            position: "absolute",
             top: 0,
             width: "100%",
             height: "100%",
             background: "rgba(0, 0, 0, 0.333)",
             display: "flex"}}></div>)}
         <img
            src={item.picture}
            width="100%"
            height="100%"
            alt="Detac logo"
            style={{
               objectFit: "cover",
               objectPosition: matches ? "60% 100%" : "center",
               height: !matches ? "100vh" : "60vh",
            }}
         />
         {matches && (
            <>
               {!moreDetails ? (
                  <div className="d-flex flex-column">
                     <p
                        style={{
                           color: !matches ? "#29588c" : "#29588c",
                           fontFamily: "MyriadPro bold",
                           fontSize: 20,
                           padding: 12,
                           marginBottom: 0,
                        }}>
                        {activeSlider?.item?.short_description}
                     </p>
                     <Text
                        afterText={activeSlider?.item?.colored_title_section}
                        matches={matches}
                        style={{
                           textAlign: "left",
                           paddingLeft: 12,
                        }}>
                        {activeSlider?.item?.transparent_title_section}
                     </Text>
                     <div>
                        <Row className="justify-content-center mx-0">
                           <p
                              style={{
                                 color: !matches ? "#29588c" : "#29588c",
                                 fontFamily: "MyriadPro",
                              }}>
                              {activeSlider?.item?.bottomText}
                           </p>
                        </Row>

                        <Row className="justify-content-center mx-0">
                           <p
                              onClick={() =>
                                 activeSlider?.item?.description && setOpened(true)
                              }
                              className="text-center"
                              style={{
                                 color: !matches ? "#29588c" : "#29588c",
                                 fontFamily: "MyriadPro",
                                 fontSize: 14,
                                 cursor: "pointer",
                              }}>
                              Find Out More
                              <BsFillPlusSquareFill
                                 style={{ paddingLeft: 5, fontSize: 25 }}
                              />
                           </p>
                        </Row>
                     </div>
                  </div>
               ) : (
                  <div
                     className={`${
                        moreDetails ? styles["more-details"] : ""
                     } hide`}
                     onClick={() => setOpened(false)}
                     style={{
                        transform: "translateY(0%)",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                     }}>
                     <p
                        style={{
                           color: "#29588c",
                           fontFamily: "MyriadPro",
                           marginBottom: 15,
                        }}>
                        {activeSlider?.item.description}
                     </p>
                     <p
                        className="text-start"
                        style={{
                           color: "#29588c",
                           fontFamily: "MyriadPro",
                        }}>
                        DETAC ensures to discard waste in a responsible manner
                        and reduce its carbon footprint through closely
                        monitoring on-site transportation, as well as employing
                        sustainable technologies and practices in the workspace.{" "}
                        <br /> DETAC employs an array of high quality equipment
                        to be utilized to its full potential and provides
                        clients with green buildings that comply with LEED
                        requirements, which value sustainable construction
                        standards.
                     </p>
                  </div>
               )}
            </>
         )}
      </div>
   );
}
