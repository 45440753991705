import React, { useState } from "react";
import { Card, useMediaQuery } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import styles from "./sister-card.module.css";

export default function SisterCard({ item }) {
   const [showText,setShowText]=useState(false)
   const matches = useMediaQuery("(max-width:600px)");

   return (
      <Card
         onClick={()=>matches&&setShowText(!showText)}
         className={`m-3 ${styles["card-hover"]}`}
         sx={{
            boxShadow: "0px 0px 50px #0000000D;",
         }}>
         <Row className="align-items-center">
            <Col lg={4} xs={4} className="align-self-baseline">
               <img
                  src={item.icon}
                  alt={"DEMARBLE"}
                  className="p-2"
                  width="100%"
               />
            </Col>
            <Col lg={8} xs={8}>
               <div className={styles["title"]}>{item.company}</div>
               <div className={styles["desc"]}>{item.description}</div>
               {!matches&&(
                  <div className={styles["more"]}>{item.hover}</div>
               )}
               {showText&&(
                  <div>{item.hover}</div>
               )}
            </Col>
         </Row>
      </Card>
   );
}
