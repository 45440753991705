import React from "react";
import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Carousels from "react-bootstrap/Carousel";
import Header from "../../../components/header/Header";
import styles from "./project-page.module.css";
import iconI from "../../../assets/images/Vector.svg";
import { Services } from "../../../apis/Services/Services";
import handleErrors from "../../../hooks/handleErrors";

export default function ProjectPage(props) {
   const [details, setDetails] = React.useState({});
   const [data, setData] = React.useState([]);

   const [activeIndex, setActiveIndex] = React.useState(0);

   React.useEffect(() => {
      getDetails(props.match.params?.id);
      // eslint-disable-next-line
   }, [props.match.params?.id]);

   const getDetails = (id) => {
      Services.getProjects(`${id}/`)
         .then((res) => {
            setDetails(res);
            getProjects(res.category.id);
         }).catch((err) => handleErrors({ err: err }));
   };

   const getProjects = (id) => {
      Services.getProjects(`?category=${id}`)
         .then((res) => {
            setData(res);
            let index = res.findIndex((item) => item.id === Number(props.match.params?.id));
            setActiveIndex(index)
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getNextProject = () => {
      if (activeIndex + 1 < data.length) {
         setActiveIndex(activeIndex + 1)
      }
   };

   const getPrevProject = () => {
      if (activeIndex !== 0) {
         setActiveIndex(activeIndex - 1)
      }
   };

   return (
      <div
         className={`pb-5 ${styles["project-details"]}`}
         style={{
            minHeight: "100vh",
         }}>
         <Header title={details?.category?.name} />
         <Row className="m-0">
            <Col
               className={styles.next}
               xs={1}
               onClick={() => getPrevProject()}
               style={{
                  cursor: "pointer",
               }}>
               <img
                  src={iconI}
                  style={{
                     transform: "rotate(90deg)",
                  }}
                  alt="prev icon"
               />
            </Col>
            <Col className="p-0" xs={10}>
               <Carousels
                  activeIndex={activeIndex}
                  indicators={false}
                  controls={false}>
                  {React.Children.toArray(
                     data.map((item) => (
                        <Carousels.Item>
                           <Container>
                              <Row>
                                 <Col lg={6}>
                                    <div className={styles.name}>{item.title}</div>
                                    <div className={styles.details}>
                                       {item.description}
                                    </div>
                                    <div className={styles["info"]}>
                                       <span>Employer: </span>
                                       {item.employer}
                                    </div>
                                    <div className={styles["info"]}>
                                       <span>Location: </span>
                                       {item.location}
                                    </div>
                                    <div className={styles["info"]}>
                                       <span>Consultant: </span>
                                       {item.project_manager}
                                    </div>
                                 </Col>
                                 <Col lg={6}>
                                    <img
                                       src={item.image}
                                       alt={item.title}
                                       width="100%"
                                       height={300}
                                       style={{
                                          objectFit: "cover",
                                       }}
                                    />
                                 </Col>
                              </Row>
                           </Container>
                        </Carousels.Item>
                     ))
                  )}
               </Carousels>
            </Col>
            <Col
               className={styles.next}
               xs={1}
               onClick={() => getNextProject()}
               style={{
                  cursor: "pointer",
               }}>
               <img
                  src={iconI}
                  alt="next icon"
                  style={{
                     transform: "rotate(270deg)",
                  }}
               />
            </Col>
         </Row>
      </div>
   );
}
