import * as React from "react";
import { CardActionArea, CardMedia, CardContent, Card } from "@mui/material";
import styles from "./news-card.module.css";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";

export default function NewsCard({ item }) {
   const history = useHistory();
   return (
      <Card
         sx={{ maxWidth: 345,padding:"2px", boxShadow: "none", margin: "auto" ,background:'transparent',cursor:'pointer'}}
         onClick={() => history.push(`/news-room/${item.id}`)}
         >
         <CardActionArea>
            <CardMedia
               component="img"
               height="300"
               image={item.image}
               style={{
                  objectFit:"contain"
               }}
               alt={item.title}
            />
            <CardContent className="px-0">
               <Row>
                  <Col className={styles.label}>{item.title}</Col>
                  <Col className={styles.date}>{item.date}</Col>
               </Row>
            </CardContent>
         </CardActionArea>
      </Card>
   );
}
