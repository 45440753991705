import React, { useState } from "react";
// import { Container } from "react-bootstrap";
import { Services } from "../../apis/Services/Services";
import Container from "../../components/Container";
import Header from "../../components/header/Header";
import LoadMore from "../../components/LoadMore/LoadMore";
import handleErrors from "../../hooks/handleErrors";
import styles from "./about-us.module.css";

export default function AboutUs() {
   const [data, setData] = useState([]);
   const [otherData, setOtherData] = useState({});

   React.useEffect(() => {
      getData();
   }, []);

   const getData = () => {
      Services.getAboutUs()
         .then((res) => {
            setOtherData(res);
            setData(res.results);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getMoreData = (res) => {
      setOtherData(res);
      setData((old) => [...old, ...res.results]);
   };

   return (
      <div id={styles["about-us"]}>
         <Header title="WHO ARE WE?" />
         <Container>
            <div className={`${styles["info"]} mb-5 wo text-center`}>
               {React.Children.toArray(
                  data.map((item) => (
                     <>
                        {item.video_url && (
                           <iframe
                              width="80%"
                              height="400"
                              src={item.video_url}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen></iframe>
                        )}
                        <div className={styles.detail}>
                           {!item.video_url && <h4>{item.title}</h4>}
                           <p>{item.description}</p>
                        </div>
                     </>
                  ))
               )}
               <LoadMore next={otherData?.next} newData={getMoreData} />
            </div>
         </Container>
      </div>
   );
}
