import React from "react";
// import { Container } from "react-bootstrap";
import Header from "../../components/header/Header";
import ServiceComponent from "../../components/service-component/ServiceComponent";
import { Services } from "../../apis/Services/Services";
import styles from "./our-services.module.css";
import LoadMore from "../../components/LoadMore/LoadMore";
import handleErrors from "../../hooks/handleErrors";
import Container from "../../components/Container";

export default function OurServices() {
   const [data, setData] = React.useState([]);
   const [otherData, setOtherData] = React.useState({});
   const [expanded, setExpanded] = React.useState(false);

   React.useEffect(() => {
      getData();
   }, []);

   const getData = () => {
      Services.getServices()
         .then((res) => {
            setOtherData(res);
            setData(res.results);
         })
         .catch((err) => handleErrors({ err: err }));
   };

   const getMoreData = (res) => {
      setOtherData(res);
      setData((old) => [...old, ...res.results]);
   };
   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };

   return (
      <div id={styles["our-services"]}>
         <Header title="OUR SERVICES" />
         <Container
            className="py-5"
            style={{
               minHeight: "100vh",
            }}>
            {React.Children.toArray(
               data.map((item,index) => (
                  <ServiceComponent
                     item={item}
                     index={index}
                     isExpanded={expanded}
                     setExpand={handleChange}
                  />
               ))
            )}
            <LoadMore next={otherData?.next} newData={getMoreData} />
         </Container>
      </div>
   );
}
