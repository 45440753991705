import React from "react";
// import { Container } from "react-bootstrap";
import { Services } from "../../apis/Services/Services";
import Container from "../../components/Container";
import Header from "../../components/header/Header";
import handleErrors from "../../hooks/handleErrors";
import styles from "./sustainablity.module.css";

export default function PrivacyPolicy() {
   const [data, setData] = React.useState({});

   React.useEffect(() => {
      getSiteConfig();
   }, []);

   const getSiteConfig = () => {
      Services.getSiteConfigration()
         .then((res) => {
            setData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };
   return (
      <div id={styles["about-us"]}>
         <Header title="PRIVACY POLICY" />
         <Container>
            <div className="wo py-5">
               <div className={styles.detail}>
                  <p>{data.privacy_policy}</p>
               </div>
            </div>
         </Container>
      </div>
   );
}
