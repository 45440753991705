import React from "react";
// import { Container } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Header from "../../../components/header/Header";
import styles from "./news-page.module.css";
import { Services } from "../../../apis/Services/Services";
import handleErrors from "../../../hooks/handleErrors";
import {
   FacebookShareButton,
   LinkedinShareButton,
   FacebookIcon,
} from "react-share";
import { AiFillLinkedin } from "react-icons/ai";
import Container from "../../../components/Container";

export default function NewsPage(props) {
   const [details, setDetails] = React.useState({});

   React.useEffect(() => {
      getDetails(props.match.params?.id);
      // eslint-disable-next-line
   }, [props.match.params?.id]);

   const getDetails = (id) => {
      Services.getNews(`${id}/`)
         .then((res) => {
            setDetails(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };
   return (
      <div
         className={`pb-5 pt-3 ${styles["news-details"]}`}
         style={{
            minHeight: "100vh",
         }}>
         <Container>
            <img
               src={details.image}
               alt="project"
               width="100%"
               className="py-2"
               height={500}
               style={{
                  objectFit: "cover",
               }}
            />
            <div className={`${styles.name} pb-3 pt-1`} style={{
                  color:'#29588C',
               }}>{details.quote}</div>

               <Header title={details.title} style={{
                  paddingBottom:0,
                  textAlign:'left'
               }} />
               <div className={`${styles.name} py-3`} style={{
                  color:'black',
               }}>{details.date}</div>

            <div className={styles.name}>{details.description}</div>

            <Row className="py-4 justify-content-center">
               <Col
                  lg={5}
                  style={{
                     background: "#3b5998",
                     height: "fit-content",
                     padding: 10,
                     cursor: "pointer",
                     marginRight: 5,
                  }}>
                  <FacebookShareButton
                     url={`http://preview.bit68.com/detac${props.location.pathname}`}
                     quote={details.title}
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                     }}>
                     <div
                        className="text-center"
                        style={{
                           color: "white",
                        }}>
                        Share on Facebook
                     </div>
                     <FacebookIcon size={32} round />
                  </FacebookShareButton>
               </Col>
               <Col
                  lg={5}
                  style={{
                     // background: "#2ACEFF",
                     height: "fit-content",
                     padding: 10,
                     cursor: "pointer",
                     marginRight: 5,
                  }}>
                  <LinkedinShareButton
                     url={`http://preview.bit68.com/detac${props.location.pathname}`}
                     title={details.title}
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                     }}>
                     <div
                        className="text-center"
                        style={{
                           color: "#326295",
                           paddingRight: 5,
                        }}>
                        Share on LinkedIn
                     </div>
                     <AiFillLinkedin
                        size={21}
                        style={{
                           color: "#326295",
                        }}
                     />
                  </LinkedinShareButton>
               </Col>
            </Row>
         </Container>
      </div>
   );
}
