import React from "react";
// import { Container } from "react-bootstrap";
import { Services } from "../../apis/Services/Services";
import Container from "../../components/Container";
import Header from "../../components/header/Header";
import handleErrors from "../../hooks/handleErrors";
import styles from "./sustainablity.module.css";

export default function Sustainablity() {
   const [data, setData] = React.useState({});

   React.useEffect(() => {
      getData();
   }, []);

   const getData = () => {
      Services.getSus()
         .then((res) => {
            setData(res);
         })
         .catch((err) => handleErrors({ err: err }));
   };
   return (
      <div id={styles["about-us"]}>
         <div className={styles["bg"]}></div>
         <div
            style={{
               position: "relative",
            }}>
            <Header title="SUSTAINABILITY" />
            <h4 className={styles.subHeader}>{data?.title}</h4>
            <Container>
               <div className="wo py-5">
                  <div className={styles.detail}>
                     <p>{data?.description}.</p>
                  </div>
               </div>
            </Container>
         </div>
      </div>
   );
}
