import React from "react";
import styles from "./person-card.module.css";

export default function PersonCard({ item }) {
   return (
      <>
         <img src={item.image} width="100%" className="mb-2" alt={item.name} />
         <div className={styles.name}>{item.name}</div>
         <div className={styles.desc}>{item.description}</div>
         <div className={styles.title}>{item.position}</div>
      </>
   );
}
